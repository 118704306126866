import {useContext} from 'react'
import { AuthContext } from '../Context/AuthContext'

const Login = () => {
    const {login,isAuth}=useContext(AuthContext)
  return (
    <div>
        <button disabled={isAuth} onClick={login}>Login</button>
    </div>
  )
}

export default Login