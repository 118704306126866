import {Routes,Route} from "react-router-dom"
import About from "./About";
import Contacts from "./Contacts";
import Home from "./Home";
import PrivateRoute from "../Components/PrivateRoute";
import Users from "./Users";
import SingleUsersPage from "./SingleUsersPage";
import NotFoundPage from "./NotFoundPage";
import Login from "./Login";
function AllRoutes(){
    return(
        <Routes>
        <Route path="/" element={<Home/>}></Route>
{/* path---> what is the path
element--> which element you want to show */}

        <Route path="/about" element={<About/>}></Route> //this means that when we write \about we get the about page  
     {/* //                                      ||
     //                                {<h1>About</h1>}
     //      iski jagah seedha jo import kar rahehain vo daal do */}
        
        <Route path="/contact" element={<Contacts/>}></Route>
        <Route path="/users" element={<PrivateRoute>
            <Users/>
        </PrivateRoute>}>
            // now firstly Private route function invoke and chaecking user authentication and if authentication is true then user will access otherwise it will redirects us to home page.children is users and Singleuserpage

        </Route>
        <Route path="/users/:user_id" element={<PrivateRoute>
            <SingleUsersPage/>
            </PrivateRoute>}> 

            </Route>
                              {/* || */}
         {/* /user/anything-->it will give single user page 
                              variable name    */}
      
      <Route path="*" element ={<NotFoundPage/>}/>
      <Route path="/login" element={<Login/>}></Route>
      </Routes>
    )
}
export default AllRoutes;

//private route is chck only authentication of users if authenticate then it will go to user or single user page otherwise it navigate to login page