
import './App.css';
import {Routes,Route,Link} from "react-router-dom";
import Navbar  from './Components/Navbar';
import AllRoutes from './Pages/AllRoutes';
function App() {
  return (
    <div className="App">
      <Navbar/>
      <AllRoutes/>
     
    </div>
  );
}

export default App;
