import React from "react";
 import {NavLink} from "react-router-dom";
 import styles from "./Navbar.module.css"
 import { useContext } from "react";
 import { AuthContext } from "../Context/AuthContext";
 const links=[
    {path:"/",title:"Home"},
     {path:"/about",title:"About"},
      {path:"/contact",title:"Contact"},
       {path:"/users",title:"users"},
       {path:"/login",title:"Login"}
];
function Navbar(){
const {logout,isAuth}=useContext(AuthContext)
    //------------Adding Style-------------------------------
    // const defaultStyle={
    //     textDecoration:"none",
    //     color:"black",
    // }

                                 //===>After some time we added css direct through Navbar.module.css

    // const activeStyle={
    //     textDecoration:"none",
    //     color:"teal",
    // }
    //for adding in NavLink we have to pass as a callback functon
    //----------------------------------------------------------
    return(
        <div style={{display:'flex', alignItems:"center",justifyContent:'space-around'
        ,width:'80%',margin:"auto"}}>
         {links.map((link)=>(
             <NavLink 
           // style={({isActive})=>{return isActive ? activeStyle :defaultStyle }}
            className={({isActive})=>{return isActive ? styles.active :styles.default }}
    //           ||
    //         Adding  Css directly through Navbar.module.css
                key={link.path} to={link.path}>{link.title} </NavLink>
        ))}
        <p>IS User Authenticated :{isAuth ? "YES" : "NO"}</p>
        {/* agar user login nhi hai to logout btn naa dikhao  */}
         <button disabled={!isAuth} onClick={logout}>LOGOUT</button> 
      </div>
    )
}export default Navbar;