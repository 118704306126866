import  {useState,createContext} from "react";
//1. CREATE
export const AuthContext=createContext();

//2. PROVIDE-->provide me index.js me jaa k wrap bhi karte hain.

//3.CONSUME===>consume users page me krenge
function AuthContextProvider({children}){  // why {children}--->Syntax which is => <BrowserRouter>
                                                                                 // <App />
                                                                                 // </BrowserRouter>
    const[isAuth,setIsAuth]=useState(true);

    const login=()=>{
        setIsAuth(true)
    };
    const logout=()=>{
        setIsAuth(false)
    }
const toggleAuth=()=>{
    setIsAuth(!isAuth )  // isAuth k saamne negation sign lgaane par humein ? false :true likhe ki jarurat nahi hai vo khud true hoga to false aur false hoga to true kar dega.

}
    return(
        <AuthContext.Provider value={{isAuth,toggleAuth,login,logout}}>{children}</AuthContext.Provider>
    )
}
 export default AuthContextProvider;

 // Navigate---> navigate uses for if user want to access s0mething from your website and as a developer u send user to firstly login then access

 // <Navigate to="/wher u want to navigate user"
 //using contextApi