import React from "react";

// 1. importing param
import { useParams,Link } from "react-router-dom";
// 3. fetching user API
const getData=(url)=>{
    return fetch(url).then((res)=>res.json())
};

 const SingleUsersPage=()=>{
   // 2. setting params 
    const {user_id}=useParams();
    const [userDetails,setUserDetails]=React.useState({});
    React.useEffect(()=>{
        getData(`https://reqres.in/api/users/${user_id}`).then((res)=> //for getting user detail and changing 
        setUserDetails(res.data)
        ) },[user_id])
    return (
        <>
        <img src={userDetails.avatar} alt="prof-pic" />
        <p>
            Name: {userDetails.first_name} {userDetails.last_name}
        </p>
        <p>
            Email: {userDetails.email}
        </p>
        <Link to="/users">GO BACK</Link> // for going back to users page.
        </>
    )
}
export default SingleUsersPage;




// 1. Now we  want that when we click the more details button ysers image and data shown at singleUserPage for that we use users link as a API and Params will give userId