import {useState,useEffect,useContext} from "react";
 //   step.1
import {Link,Navigate,useNavigate,useSearchParams}from "react-router-dom";
   
                         
const getData=(url)=>{
    return fetch(url).then((res) => res.json());
};
const getCurrentPageFromUrl=(value)=>{
    value=Number(value);
    if(typeof value==="number" && value<=0){
        value=1;
    }
    if(!value){
        value=1;
    }
    return value;
};

const Users=()=>{
    const [data,setData]=useState({})
    const [searchParams,setSearchParams]=useSearchParams()// seachparams are using for searching things and when we refresh the page if we are at page 2 it will redirected to us on page no. 1 which i want to stop theref0re useparams in the market
    //   ||
    //   step.2
    const intialPage=getCurrentPageFromUrl(searchParams.get("page"))
     const [page,setPage]=useState(intialPage) // pagintion line no.1 intialPage
     const [text,setText]=useState(searchParams.get ("q") || ""); //added 1 more parameter q

    
                    

    const navigate=useNavigate();

    //on component load
    //i want to get a list of users in this page
    //i want to render it to the UI
    // return  <h1>Users Page</h1>
    useEffect(()=>{
        getData(`https://reqres.in/api/users?page=${page}`).then((res)=>{
            setData(res)
        })
    },[page])
    
    useEffect(()=>{
        setSearchParams({page,q:text})  // step.3 searchParam //added 1 more parameter q
    },[page,text]);//passing text

    console.log(typeof page);
            return(
                <>
                <h1>Users</h1>
    <input type="text" value={text} onChange={(e)=>setText(e.target.value)}/> 
               {/* <button onClick={()=>navigate('/')}>GO TO HOME PAGE USING useNavigate hook</button>
                <Link to="/">
                    <button>GO TO HOMEPAGE USING Link</button>
                </Link> */}
                <h3>{text}</h3>
                { data ?.data?.map((user)=>(
                    <div key={user.id} style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <img src={user.avatar} alt="profile-pic"/>
                    <Link to={`/users/${user.id}`}> More Details</Link>
                    </div>               
                    
                ))}
                
                <button disabled={page===1} onClick={()=>setPage(page-1)}>PREV</button> 
                <button>{page}</button>
                <button disabled={page>=2} onClick={()=>setPage(page+1)}>NEXT</button>

                
                </>
            )
}
export default Users;


// navigate --> navigate ka use path ko change karne k liye kiya jaata hai but authentication me hi navigate ka use karein kyuki navigation new tab me nahi open hota hai

// jabki <Link> new tab me bhi open kiya jaa sakta HTMLDetailsElement.